'use strict';
var base = require('client_core/product/base');

/**
 * Updates availability messages for buybox
 * @param {Object} data - The result of the controller call
 * @param {jQuery} $addToCartButton - The add to cart button
 * @param {jQuery} $availabilityMessage - The availability messages field
 */
function updateAvailability(data, $addToCartButton, $availabilityMessage) {
    var availabilityValue = '';
    var availabilityMessages = data.messages;

    availabilityMessages.forEach(function (message) {
        availabilityValue += '<li><div>' + message + '</div></li>';
    });

    $availabilityMessage.empty().html(availabilityValue);

    $addToCartButton.attr('disabled', !data.success);
}

/**
 * Updates availability for offers when quantity is changed
 */
function offerAvailability() {
    var quantityInput = $('.pdp-qty-cart-container .offer-quantity-select');
    var oldQty = quantityInput.val();
    quantityInput.attr('lastSelectedQty', oldQty);

    $(document).on('change', '.offer-quantity-select', function (e) {
        e.preventDefault();
        $.spinner().start();
        var thisInput = $(this);
        var url = $(this)
            .children('option:selected')
            .data('url');
        var $addToCartButton;
        var $availabilityMessage;

        $addToCartButton = $(this)
            .closest('.offer-details')
            .find('.offer-add-to-cart');
        $availabilityMessage = $(this)
            .closest('.offer-details')
            .find('.offer-availability-msg');

        $.ajax({
            url: url,
            method: 'get',
            success: function (data) {
                updateAvailability(
                    data.result,
                    $addToCartButton,
                    $availabilityMessage
                );
                
                var isPDPdata = thisInput.parents().hasClass('product-data');
                if($('.product-detail').find('.bundle-items').length > 0 && isPDPdata) {
                    var newQty = thisInput.val();
                    oldQty = thisInput.attr('lastSelectedQty');
                    base.addItemsToCartText(thisInput, oldQty, newQty);
                }
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });
}

module.exports = {
    offerAvailability: offerAvailability
};
