'use strict';

var formValidation = require('base/components/formValidation');
var productBase = require('core/product/base');
var wishlist = require('core/wishlist/wishlist');
var recaptcha = require('core/components/recaptcha');
var quickview = require('core/product/quickView');
var modal = require('core/components/modal');

function sendToFriends() {
    $('body').on('submit', '.send-friend-form', function (e) {
        var form = $(this);
        e.preventDefault();
        var $formBody = form.closest('.modal-dialog');
        var url = form.attr('action');
        $formBody.spinner().start();
        recaptcha.check(e, {
            url: url,
            type: 'post',
            dataType: 'json',
            data: form.serialize(),
            success: function (data) {
                $formBody.spinner().stop();
                if (!data.success) {
                    formValidation(form, data);
                } else {
                    wishlistSent('.send-friend', data);
                }
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                }
                $formBody.spinner().stop();
            }
        });
        return false;
    });
}

function sendToFurnitureExperts() {
    $('body').on('submit', '.send-experts-form', function (e) {
        var form = $(this);
        e.preventDefault();
        var url = form.attr('action')
        var $formBody = form.closest('.modal-dialog');;
        $formBody.spinner().start();
        recaptcha.check(e, {
            url: url,
            type: 'post',
            dataType: 'json',
            data: form.serialize(),
            success: function (data) {
                $formBody.spinner().stop();
                if (!data.success) {
                    formValidation(form, data);
                } else {
                    wishlistSent('.send-experts', data);
                }
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                }
                $formBody.spinner().stop();
            }
        });
        return false;
    });
}


function searchWishlists() {
    $('body').on('submit', '#wishlist-search', function (e) {
        e.preventDefault();
        let $target = $(e.target);
        let $formFields = $target.find('input[id^="wishlist-"]');
        let $firstNameField = $target.find('[name="searchFirstName"]');
        let $lastNameField = $target.find('[name="searchLastName"]');
        let $emailField = $target.find('[name="searchEmail"]');

        $formFields.find('.is-invalid').removeClass('is-invalid');

        if ($firstNameField.val() == '' && $lastNameField.val() == '' && $emailField.val() == '') {
            $formFields.addClass('is-invalid').siblings('.invalid-feedback').html($('.search-submit').data('missingMsg'));
            return false;
        } else {
            // remove the event listener to allow the form to submit
            $('body').off('submit', '#wishlist-search');
            $(this).submit();
        }
    });
}


function wishlistSent(classPrefix, data) {
    $(classPrefix + '-title').empty();
    $(classPrefix + '-body').empty()
    .addClass('text-center')
    .html('<span class="h4-alt">' 
        + data.receivedMsgHeading
        + '</span>'
    );
    $(classPrefix +'-send-email-btn').empty()
        .addClass('text-center')
        .html('<button type="button" class="btn btn-tertiary" data-dismiss="modal" aria-label="Close">'
        + data.buttonText 
        + '</button>'
        );
}

function removeFromWishlist() {
    $('body').on('click', '.remove-from-wishlist', function (e) {
        e.preventDefault();
        var url = $(this).data('url');
        var isWishlistLanding = $('.wishlist-top').length > 0;

        // If user is in my account page, call Wishlist-RemoveProductAccount() end point and replace html with response
        if (!isWishlistLanding) {
            $('.wishlist-account-card').spinner().start();
            $.ajax({
                url: url,
                type: 'get',
                dataType: 'html',
                data: {},
                success: function (html) {
                    $('.wishlist-account-card>.order-card').remove();
                    $('.wishlist-account-card').append(html);
                    $('.wishlist-account-card').spinner().stop();
                },
                error: function () {
                    var $elToAppend = $('.wishlist-account-card');
                    $elToAppend.spinner().stop();
                    var msg = $elToAppend.data('error-msg');
                    displayMessage($elToAppend, msg);
                }
            });
        // else user is in wishlist landing page, call Wishlist-RemoveProduct() end point and re-render container page
        } else {
            $.spinner().start();
            $.ajax({
                url: url,
                type: 'get',
                dataType: 'json',
                data: {},
                success: function (data) {
                    if (data.listIsEmpty) {
                        $('.wishlist-empty-container').removeClass('d-none');
                        $('.wishlist-share-container').addClass('d-none');
                        $('.wishlist-list-container').addClass('d-none');
                        $('.mathis-wishlist-share-banner').addClass('d-none');
                        $('.custom-checkbox-toggle').addClass('d-none');
                    }
                    var pageNumber = $('.wishlistItemCardsData').data('page-number') - 1;
                    wishlist.renderNewPageOfItems(pageNumber, false);
                    quickview.removeEventListeners()
                },
                error: function () {
                    $.spinner().stop();
                    var $elToAppendWL = $('.wishlistItemCards');
                    var msg = $elToAppendWL.data('error-msg');
                    displayMessage($elToAppendWL, msg);
                }
            });
        }
    });
}

/**
 * attach on click on wishlist-show edit button to launch modal
 */
 function viewProductViaEdit() {
    $('body').on('click', '.edit-add-to-wishlist .edit', function (e) {
        e.preventDefault();
        var editProductUrl = $(this).attr('href');
        if(editProductUrl.indexOf('Wishlist') != -1) {
            $(e.target).trigger('editwishlistproduct:show');
            modal.getModalHtmlElement('editWishlistProductModal', 'quick-view-dialog');
            // overwriting the wishlist fillModalElement so that this can be consistent with the QuickView
            quickview.fillModalElement(editProductUrl, '#editWishlistProductModal');
            quickview.init();
        } else {
            window.location = this.href;
        }
    });
}
/**
 * initialize an event to close an error modal on the wishlist results page
 */
function initEvents() {
    $('body').on('click', '.icon-close-small', function (e) {
        $(e.target).closest('.alert-danger').addClass('d-none');
    });
}

module.exports = {
    sendToFriends: sendToFriends,
    sendToFurnitureExperts: sendToFurnitureExperts,
    searchWishlists: searchWishlists,
    removeFromWishlist: removeFromWishlist,
    viewProductViaEdit: viewProductViaEdit,
    initEvents: initEvents,
    init : function() {
        wishlist.removeFromWishlist = removeFromWishlist;
        wishlist.viewProductViaEdit = viewProductViaEdit;
        wishlist.init();
        module.exports.initEvents();
        module.exports.sendToFriends();
        module.exports.sendToFurnitureExperts();
        module.exports.searchWishlists();
    }
}
