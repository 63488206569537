'use strict';

var base = require('core/components/footer');
var scrollAnimate = require('core/components/scrollAnimate');

function displayMessage(success, msg) {
    var status = success ? 'alert-success' : 'alert-danger';

    if ($('.email-signup-message').length === 0) {
        $('body').append('<div class="email-signup-message"></div>');
    }

    $('.email-signup-message').append('<div class="email-signup-alert text-center alert ' + status + '">' + msg + '</div>');

    setTimeout(function () {
        $('.email-signup-message').remove();
    }, 3000);
}

var footer = {
    'elementNameMap': {
        'hpEmailSignUp': 'hpEmailSignUp'
    },
    'backToTop' : function() {
    	var $backToTop = $('.back-to-top');
        $backToTop.click(function () {
            scrollAnimate();
        });
        $(window).scroll(function(e) {
            var distanceToTop = $(this).scrollTop();
            if (distanceToTop > 1000) {
                $backToTop.fadeIn();
            } else if ($backToTop.is(':visible') && distanceToTop < 1000) {
                $backToTop.fadeOut();
            }
        });
    },
    'forms': 'footer .email-signup-form form',
    'init': function () {
        var nameMap = this.elementNameMap;

        // Prevent Multi Form event collision
        var $forms = $(this.forms);
        $forms.each(function () {
            var $form = $(this);
            var $inputs = $form.find(':input:not(:disabled)');

            //TODO: Security Update with CSRF

            // Update Form Name for SFRA compatibility
            if (nameMap) {
                $inputs.each(function () {
                    var $input = $(this);
                    var oldName = $input.attr('name');
                    var newName = oldName && nameMap[oldName];

                    if (newName) {
                        $input.attr('name', newName);
                    }
                });
            }

            $form.on('submit', function (e, urlOverride) {
                e.preventDefault();

                var $btn = $(this);
                var xhr = $form.data('xhr');

                $form.data('xhr', $.ajax({
                    url: urlOverride || $form.attr('action'),
                    type: $form.attr('method') || 'post',
                    data: $inputs.serialize(),
                    dataType: 'json',
                    beforeSend: function () {
                        // Drop duplicate requests
                        xhr && xhr.abort && xhr.abort();

                        $.spinner().start();

                        $btn.attr('disabled', true);
                    },
                    success: function (data) {
                        displayMessage(data.success, data.msg);
                        $form.trigger('footer:signup:success', data);
                    },
                    error: function ($xhr, err, other) {
                        var msg = $xhr.responseJSON && $xhr.responseJSON.message;
                        displayMessage(false, msg);
                    },
                    complete: function () {
                        $.spinner().stop();
                        $btn.removeAttr('disabled');
                        $form[0].reset();
                    }
                }));
            })

            // For SFRA compatibility
            $form.find('.subscribe-email').on('click', function (e) {
                e.preventDefault();
                $form.trigger('submit', $(this).data('href'));
            });
        });
    }
};

$('document').ready(function () {
    var adBlocker = false;
    try {
        let fakeAd = document.createElement("div");
        fakeAd.className = "textads banner-ads banner_ads ad-unit ad-zone ad-space adsbox"
        fakeAd.style.height = "1px"
        document.body.appendChild(fakeAd)
        let x_width = fakeAd.offsetHeight;
        if (!x_width) {
            adBlocker = true;
        }
    } catch (e) {
        //
    }

    const el = "<div class='chat-service-notice'><h4>Your ad blocker may block the chat service</h4><p>If you have problems, please disable your adblocker on this site</p></div>";

    $('body').on('mouseover', '#podium-bubble', function (e) {
        if (adBlocker) {
            $('#podium-website-widget').append(el);

            window.setTimeout(function () {
                $('.chat-service-notice').remove();
            }, 5000);
        }
    });

    $('body').on('blur', 'input[name=hpEmailSignUp]', function () {

        if ($(this).val()) {
            var consentObj = {
                event: 'consent',
                action: 'accept',
                category: 'email',
                valid_until: 'unlimited',
                placement: 'footer'
            };

            $('body').trigger('gtm:pushData', consentObj);
        }
    });
    
    function collapsibleContent(element) {
        var element = element;
        var target = element.find('.title');
        target.off().on('click', function(e) {
            e.preventDefault();
            var parentBlock = $(this).closest(element);
            var activeClass = parentBlock.hasClass('active');
            setTimeout(() => {
                activeClass ? parentBlock.removeClass('active') : parentBlock.addClass('active');   
            }, 150);
        });
    }
    collapsibleContent($('.collapsible-xl'));
    collapsibleContent($('.collapsible-md'));
});

module.exports = footer;
