'use strict';

function toggleViewTitle() {
    $('body').on('click', '.order-details .delivery-info', function(e) {
        var text = $('.order-details .delivery-info').text();
        $('.card-title').text(text=="View" ? "Hide" : "View");
    });
}

module.exports = {
    toggleViewTitle: toggleViewTitle
}
