'use strict';

var base = require('client_core/product/base');
var recaptcha = require('core/components/recaptcha');

$(document).ready(function() {
    $('body').on('click', '.quote-checkout-btn', function(e) {
        var url = $(this).data('action'),
            products = $(this).data('products'),
            salespeople = $(this).data('salespeople');

        $.ajax({
            url: url,
            data: {
                products: JSON.stringify(products),
                salespeople: JSON.stringify(salespeople)
            },
            method: 'POST',
            success: function(data) {
                base.methods.handlePostCartAdd(data);
                $('body').trigger('product:afterAddToCart', data);
                $('body').trigger('product:afterAddToCartQuickview', data);
                $.spinner().stop();
                base.methods.miniCartReportingUrl(data.reportingURL);
                if (data.redirectUrl) {
                    window.location.href = data.redirectUrl;
                }
            },
            error: function(error) {
                $.spinner().stop();
            }
        });
    });

    $('body').on('storis:updateStorisDates', function(e, data) {
        var $datePicker = $('.storis-date-picker');
        if (data.availabilityDates) {
            $datePicker.show();
            updateDates();
            $('.storis-date-picker').data('available-dates', data.availabilityDates);
            $('.delivery-error-message-js').hide();
        } else {
            $datePicker.hide();
        }

        function updateDates() {
            var dateString = data.availabilityDates;
            // regex to replace leading 0's in dates and months to match the date format for jquery datepicker
            var availableDates = dateString.replaceAll(/-([0]{1})/g, '-').split(',');
            var storisDeliveryDateSelector = $('#storisDeliveryDate');
            storisDeliveryDateSelector.datepicker('destroy');
            storisDeliveryDateSelector.datepicker({
                format: 'yyyy-MM-dd',
                filter: function(date, view) {
                    let ymd = date.getFullYear() + "-" + (date.getMonth()+1) + "-" + date.getDate();

                    if ($.inArray(ymd, availableDates) != -1) {
                        return true;
                    } else {
                        return false;
                    }
                }
            });
        }
    });

    $('body').on('checkout:updateCheckoutView', function (e, data) {
        $('body').trigger('storis:updateStorisDates', {
            availabilityDates: data.order.availabilityDates
        });
    });

    $('body').on('submit', '.trackorder-form', function (e) {
        var form = $(this);
        e.preventDefault();
        var $formBody = form.closest('.order-lookup');
        var url = form.attr('action');
        $formBody.spinner().start();
        var recaptchaEnabled = $('.recaptcha-enabled').data('recaptcha');
        if (recaptchaEnabled) {
        	recaptcha.check(e, {
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    $formBody.spinner().stop();
                    if (!data.success) {
                        $('.order-lookup-error .alert').removeClass('d-none').text(data.errorMsg);
                    } else {
                        if(data.redirectUrl) {
                            location.href = data.redirectUrl;
                        } else {
                            $('.order-lookup-error .alert').removeClass('d-none').text('Something went wrong, please refresh the page and try again.');
                        }
                    }
                },
                error: function (err) {
                    if (err.errorMsg) {
                        $('.order-lookup-error .alert').removeClass('d-none').text(err.errorMsg);
                    } else {
                        $('.order-lookup-error .alert').removeClass('d-none').text('Something went wrong, please refresh the page and try again.');
                    }
                    $formBody.spinner().stop();
                }
            });
        } else {
        	$.ajax({
            	url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    $formBody.spinner().stop();
                    if (!data.success) {
                        $('.order-lookup-error .alert').removeClass('d-none').text(data.errorMsg);
                    } else {
                        if(data.redirectUrl) {
                            location.href = data.redirectUrl;
                        } else {
                            $('.order-lookup-error .alert').removeClass('d-none').text('Something went wrong, please refresh the page and try again.');
                        }
                    }
                },
                error: function (err) {
                    if (err.errorMsg) {
                        $('.order-lookup-error .alert').removeClass('d-none').text(err.errorMsg);
                    } else {
                        $('.order-lookup-error .alert').removeClass('d-none').text('Something went wrong, please refresh the page and try again.');
                    }
                    $formBody.spinner().stop();
                }
            });
        }
        return false;
    });
});
