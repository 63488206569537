'use strict';


module.exports = function() {
    $("#registration-form-password").focus(function() {
        $('.password-criteria-popover').show();
    });
    $("#registration-form-password").blur(function() {
        $('.password-criteria-popover').hide();
    });
    $("#newPassword").focus(function() {
        $('.password-criteria-popover').show();
    });
    $("#newPassword").blur(function() {
        $('.password-criteria-popover').hide();
    });
    //show password checkbox
    $(".custom-control-show-pw").on('click', function(){
        var $passwordField = $('input#'+$(this).attr('for'));
        var $label = $('label[for='+$(this).attr('id')+']');
        $(this).prop('checked') ? $passwordField.attr("type", "text") : $passwordField.attr("type", "password");
        if($(this).prop('checked')) {
            $label.html('Hide');
        } else {
            $label.html('Show');
        }
    });

    //Registration Modals
    if ($('#welcome-modal').length > 0) {
        $('#welcome-modal').modal('show');
    }

    $('body').on('hidden.bs.modal', '#welcome-modal', function () {
        $('#footercontent').removeClass('registration');
        $('.container.account-page').removeClass('registration');
    });

    $('.start-shopping-btn').on('click', function() {
        window.location.href = $(this).data('href');
    });
}
