'use strict';

function cancelMembership() {
    var cancelBtn = $('.cancel-membership-js');
    var dataBlock = $('#data-block');
    cancelBtn.on('click', function () {
        var customerID = cancelBtn.data('customer-id').toString();
        var successHeader = dataBlock.data('success-header');
        var successBody = dataBlock.data('success-body');
        var errorHeader = dataBlock.data('error-header');
        var errorBody = dataBlock.data('error-body');
        var url = 'https://content.mathisbrothers.com/wgInv/annexCloud.aspx?mode=cancelMemberShip&custID=' + customerID;
        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            success: function (data) {
                if (typeof data == 'string') {
                    data = JSON.parse(data);
                }

                if (data && data.success) {
                    $('#cancelMembershipModal .modal-body p').text(successBody);
                    $('#cancelMembershipModal .modal-title').text(successHeader);
                } else {
                    $('#cancelMembershipModal .modal-body').html(errorBody);
                    $('#cancelMembershipModal .modal-title').text(errorHeader);
                }
                $.spinner().stop();
            },
            error: function () {
                $('#cancelMembershipModal .modal-body').html(errorBody);
                $('#cancelMembershipModal .modal-title').text(errorHeader);
                $.spinner().stop();
            }
        });
        $('#cancelMembershipModal .modal-footer').hide();
    });
}

module.exports.cancelMembership = cancelMembership;
