'use strict';

var keyboardAccessibility = require('base/components/keyboardAccessibility');
var SiteConstants = require('constants/SiteConstants');
var hoverIntent = require('jquery-hoverintent');

var $windowWidth = $(window).width() < SiteConstants.BreakpointSizes.xl ? $(window).width() : SiteConstants.BreakpointSizes.xl;

function clearSelection(element) {
    var $currentPane = $(element).closest('.custom-dropdown.show');

    $currentPane.removeClass('show');

    // Adjust DOM after drawer finishes closing (mobile) or dropdown finishes hiding (desktop)
    setTimeout(() => {
        $currentPane.find('.nav-link').attr('aria-expanded', 'false');
        $currentPane.find('.dropdown-menu').attr('aria-hidden', 'true');
        $currentPane.find('.top-category').detach();
        $currentPane.find('.nav-menu').detach();

        if (!$currentPane.closest('.custom-dropdown.show').length) {
            $currentPane.closest('.menu-group').find('.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'false');
        }
    }, SiteConstants.TransitionSpeed);
};

function toggleDrawer(status) {
    var $header = $('header');
    var $headerNav = $header.find('.header-nav');
    var $mainMenu = $header.find('.main-menu');
    var $navbar = $mainMenu.find('.navbar-nav');
    var $modalBackground = $headerNav.children('.modal-background');
    var $navbarToggler = $headerNav.find('.navbar-toggler');
    var mainMenuWidth = $mainMenu.outerWidth() || '321px';

    if (status === 'open') {
        $('html').scrollTop($headerNav.offset().top);
        $('html').addClass('lock-scroll');
        $('body').addClass('mobile-menu-in');
        $('header .search').hide();
        $('header .header .brand').hide();
        $('header .header .minicart').hide();
        $('header .pre-header-nav').hide();
        $mainMenu
            .addClass('in')
            .attr('aria-hidden', 'false')
            .siblings().attr('aria-hidden', 'true');
        $modalBackground
            .fadeIn(SiteConstants.TransitionSpeed);
        $header
            .siblings().attr('aria-hidden', 'true');
        $navbar
            .find('.nav-link').first().focus();
        $navbarToggler
            .css('left', mainMenuWidth);
    } else {
        $('header .search').show();
        $('header .header .brand').show();
        $('header .header .minicart').show();
        $('header .pre-header-nav').show();
        $('body').removeClass('mobile-menu-in');
        $navbar
            .find('.nav-menu, .top-category').detach();
        $navbar
            .find('.thirdCatOpen').removeClass('thirdCatOpen');
        $navbar
            .find('.show').removeClass('show');
        $mainMenu
            .removeClass('in')
            .siblings().attr('aria-hidden', 'false');
        $header
            .siblings().attr('aria-hidden', 'false');
        $modalBackground
            .fadeOut(SiteConstants.TransitionSpeed);

        setTimeout(() => {
            $modalBackground.css('top', '');
            $('html').removeClass('lock-scroll');
            $headerNav.addClass('transparent-nav');
        }, SiteConstants.TransitionSpeed);
    }
};

function moveMenu() {
    if ($('.non-full-mega .megamenu > .dropdown-menu').hasClass('show')) {
        $('.non-full-mega .megamenu > .dropdown-menu.show').each(function(index) {
            var $left = $(this).parent().position().left;
            var $minWidth = $(this).width();
            if (($left + $minWidth) > $windowWidth) {
                $(this).css({
                    'left': $windowWidth - $minWidth - SiteConstants.Spacer + 'px',
                })
            }
        });
    }
};

function resetFlag(isResized) {
    if (isResized) {
        // remove all 'row#-col#' classes
        $('.navbar-nav .subcat-rows > li').attr(
            'class',
            $('.navbar-nav .subcat-rows > li').attr('class').replace(/\brow\d+-col\d+\b/g, '')
        );
        isResized = false;
    }
    return isResized;
}

function moveDropDownItem($dropDownItems) {
    if ($dropDownItems.length > 0) {
        let hasAdjusted = $dropDownItems.hasClass('row0-col0');
        if (!hasAdjusted) {
            let row = 0;
            let col = 0;
            let rowPositionTop = Math.round($dropDownItems.first().position().top);
            let initialRowMarginTop = rowPositionTop;

            for (let item of $dropDownItems) {
                let $item = $(item);

                // in case there's space outside of flex items
                let currentItemPositionTop = Math.round($item.position().top);
                if (rowPositionTop != currentItemPositionTop) {
                    rowPositionTop = currentItemPositionTop;
                    col = 0;
                    row++;
                }

                // starting from second row, calculate the gap and pull them up:
                // gap = current item's top position - bottom position of previous row's item
                if (row > 0) {
                    let previousRowItemBottomPosition = Math.round($dropDownItems.siblings('.row' + (row - 1) + '-col' + col).data('bottomPosition'));
                    var topGap = rowPositionTop - (previousRowItemBottomPosition || initialRowMarginTop);
                    $item.css('margin-top', -topGap + 'px');
                } else {
                    $item.css('margin-top', 0);
                }
                // set current item's height and save its bottom position
                // to for next row's item to use
                $item.css('height', 'fit-content');
                let bottomPosition = rowPositionTop + $item.outerHeight();
                $item.data('bottomPosition', bottomPosition - (topGap || 0));
                $item.addClass('row' + row + '-col' + col);

                col++;
            }
        }
    }
}

function headerBanner() {
    var headerBannerStatus = window.sessionStorage.getItem('hide_header_banner');
    $('.header-banner .close').on('click', () => {
        $('.header-banner').addClass('d-none');
        window.sessionStorage.setItem('hide_header_banner', '1');
    });

    if (!headerBannerStatus || headerBannerStatus < 0) {
        $('.header-banner').removeClass('d-none');
    }
}

function kbAccessibility() {
    keyboardAccessibility('.main-menu .nav-link, .main-menu .dropdown-link, .header-account-container .nav-link, .header-wishlist-container .nav-link',
        {
            40: function(menuItem) { // down
                if (menuItem.hasClass('nav-item')) { // top level
                    $('.navbar-nav .show').removeClass('show')
                        .children('.dropdown-menu')
                        .removeClass('show');
                    menuItem.addClass('show').children('.dropdown-menu').addClass('show');
                    menuItem.children('.dropdown-menu.show').find('ul > li > a').first().focus();
                    $(this).attr('aria-expanded', 'true');
                } else if (menuItem.is('.header-account-container .custom-dropdown, .header-wishlist-container .custom-dropdown, .header-support-container')) { // account/support
                    if(menuItem.closest('.header-account-container').length) {
                        menuItem = $('.header-account-container');
                    }
                    if(menuItem.closest('.header-wishlist-container').length) {
                        menuItem = $('.header-wishlist-container');
                    }
                    menuItem.find('.custom-dropdown').addClass('show').children('.dropdown-menu').addClass('show');
                    //menuItem.find('ul > li > a').first().focus();
                    menuItem.find('.dropdown-toggle').attr('aria-expanded', 'true');
                } else {
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    if (!(menuItem.next().length > 0)) { // if this is the last menuItem
                        menuItem.parent().parent().find('li > a').first().focus(); // set focus to the first menuitem
                    } else {
                        menuItem.next().children().first().focus();
                    }
                }
            },
            39: function(menuItem) { // right
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    menuItem.next().children().first().focus();
                } else if (menuItem.is('.header-account-container .custom-dropdown, .header-wishlist-container .custom-dropdown, .header-support-container')) { // account/support
                    if(menuItem.closest('.header-account-container').length) {
                        menuItem = $('.header-account-container');
                    }
                    if(menuItem.closest('.header-wishlist-container').length) {
                        menuItem = $('.header-wishlist-container');
                    }
                    menuItem.find('.custom-dropdown').addClass('show').children('.dropdown-menu').addClass('show');
                    menuItem.find('ul > li > a').first().focus();
                    menuItem.find('.dropdown-toggle').attr('aria-expanded', 'true');
                } else if (menuItem.hasClass('dropdown')) {
                    menuItem.addClass('show').children('.dropdown-menu').addClass('show');
                    $(this).attr('aria-expanded', 'true');
                    menuItem.find('ul > li > a').first().focus();
                }
            },
            38: function(menuItem) { // up
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                } else if (menuItem.is('.minicart')) { // minicart
                    menuItem.find('.popover').removeClass('show');
                } else if (menuItem.is('.header-account-container .custom-dropdown, .header-wishlist-container .custom-dropdown, .header-support-container')) { // account/support
                    if(menuItem.closest('.header-account-container').length) {
                        menuItem = $('.header-account-container');
                    }
                    if(menuItem.closest('.header-wishlist-container').length) {
                        menuItem = $('.header-wishlist-container');
                    }
                    menuItem.find('.custom-dropdown').removeClass('show').children('.dropdown-menu').removeClass('show');
                    menuItem.find('.dropdown-toggle').attr('aria-expanded', 'false');
                } else if (menuItem.prev().length === 0) { // first menuItem
                    menuItem.parent().parent().removeClass('show')
                        .children('.nav-link')
                        .attr('aria-expanded', 'false');
                    menuItem.parent().children().last().children().first().focus(); // set the focus to the last menuItem
                } else {
                    menuItem.prev().children().first().focus();
                }
            },
            37: function(menuItem) { // left
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    menuItem.prev().children().first().focus();
                } else if (menuItem.is('.minicart')) { // minicart
                    menuItem.find('.popover').removeClass('show');
                } else if (menuItem.is('.header-account-container .custom-dropdown, .header-wishlist-container .custom-dropdown, .header-support-container')) { // account/support
                    if(menuItem.closest('.header-account-container').length) {
                        menuItem = $('.header-account-container');
                    }
                    if(menuItem.closest('.header-wishlist-container').length) {
                        menuItem = $('.header-wishlist-container');
                    }
                    menuItem.find('.custom-dropdown').removeClass('show').children('.dropdown-menu').removeClass('show');
                    menuItem.find('.dropdown-toggle').attr('aria-expanded', 'false');
                } else {
                    menuItem.closest('.show').removeClass('show')
                        .closest('li.show').removeClass('show')
                        .children().first().focus().attr('aria-expanded', 'false');
                }
            },
            27: function(menuItem) { // escape
                if (menuItem.is('.minicart')) { // minicart
                    menuItem.find('.popover').removeClass('show');
                } else if (menuItem.is('.header-account-container .custom-dropdown, .header-wishlist-container .custom-dropdown, .header-support-container')) { // account/support
                    if(menuItem.closest('.header-account-container').length) {
                        menuItem = $('.header-account-container');
                    }
                    if(menuItem.closest('.header-wishlist-container').length) {
                        menuItem = $('.header-wishlist-container');
                    }
                    menuItem.find('.custom-dropdown').removeClass('show').children('.dropdown-menu').removeClass('show');
                    menuItem.find('.dropdown-toggle').attr('aria-expanded', 'false');
                } else {
                    var parentMenu = menuItem.hasClass('show')
                    ? menuItem
                    : menuItem.closest('.nav-item.show');
                    parentMenu.children('.show').removeClass('show');
                    parentMenu.removeClass('show').children('.nav-link')
                        .attr('aria-expanded', 'false');
                    parentMenu.children().first().focus();
                }
            }
        },
        function () {
            return $(this).parent();
        }
    );

    keyboardAccessibility('.header-account-container, .header-wishlist-container',
        {
            27: function(menuItem) { // escape
                var navLink = menuItem.find('.dropdown-toggle.focus-visible');
                if(navLink.length) {
                    menuItem = navLink.closest('.custom-dropdown');
                }
                menuItem.find('.dropdown-menu').removeClass('show');
                menuItem.find('.dropdown-toggle').attr('aria-expanded', 'false');
            }
        },
        function () {
            return $(this).parent();
        }
    );
}

function searchExpand() {
    //if only search icon is visible, show the input on focus
    $( ".search-field" ).focus(function() {
        $(this).closest('.site-search').addClass('expanded');
    });
}

function dropdownMenu() {
    var isResized = false;
    // Custom dropdown behaviors for top menu
    var $dropdownMenu = $('.custom-dropdown:not(.disabled) [data-toggle="dropdown"]');
    $dropdownMenu.on('click', event => {
        event.stopPropagation(); // Prevent Bootstrap dropdown click events
        if (window.isMobile()) {
            // Drawer behavior on mobile
            event.preventDefault();
            var $dropdownLink = $(event.target);
            var $dropdown = $dropdownLink.closest('.custom-dropdown');
            var $li = $('<li class="dropdown-item top-category" role="button"></li>');
            var $closeMenu = $('<li class="nav-menu"></li>');
            var link = $dropdownLink.clone().removeClass('dropdown-toggle')
                .removeAttr('data-toggle')
                .removeAttr('aria-expanded')
                .attr('aria-haspopup', 'false');
            $li.append(link);
            $closeMenu.append($('.close-menu').first().clone());
            $dropdown.children('.dropdown-menu')
                .prepend($li)
                .prepend($closeMenu)
                .attr('aria-hidden', 'false');
            $dropdown.addClass('show');
            $dropdownLink.attr('aria-expanded', 'true');
            $dropdown.closest('.menu-group').find('.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'true');
            $(link).focus();
            $('.dropdown-menu').scrollTop(0);
        }
    });

    // Desktop - open menu using hoverIntent to prevent unintentional opening
    $dropdownMenu.hoverIntent({
        over: event => {
            if (!window.isMobile()) {
                var eventElement = event.target;
                // Close all dropdowns
                $('.navbar-nav > li').each((index, element) => {
                    if (!$.contains(element, eventElement)) {
                        var $navItem = $(element);
                        $navItem.find('.show').each(() => {
                            clearSelection(element);
                        });
                        if ($navItem.hasClass('show')) {
                            $navItem.removeClass('show');
                            $navItem.children('ul.dropdown-menu').removeClass('show');
                            $navItem.children('.nav-link').attr('aria-expanded', 'false');
                        }
                    }
                });
                // Open current dropdown
                $(eventElement).parent().addClass('show');
                $(eventElement).siblings('.dropdown-menu').addClass('show').attr('aria-hidden', 'false');
                $(eventElement).attr('aria-expanded', 'true');
                moveMenu();
                let $dropDownItems = $(eventElement).parent().find('.dropdown-menu.show .menu-subcategories > li').filter(':visible');
                isResized = resetFlag(isResized);
                moveDropDownItem($dropDownItems);
            }
        },
        out: () => {
            // this 'out' handler is left empty, since it's triggered from parent, handled in mouseleave event below
        },
        interval: 50
    });

    // Desktop - close menu on mouseleave
    $dropdownMenu.parent().on('mouseleave', event => {
        if (!window.isMobile()) {
            var $dropdown = $(event.currentTarget);
            // Close current dropdown
            $dropdown.removeClass('show');
            $dropdown.children('.dropdown-menu').removeClass('show').attr('aria-hidden', 'true');
            $dropdown.children('.nav-link').attr('aria-expanded', 'false');
        }
    });

    $(window).on('resize', function () {
        isResized = true;
    });
}

function megaLeftHover() {
    //Megamenu Mega-left menu
    $('.mega-left-menu-link').hoverIntent( function() {
            var menuName = this.id,
                menuRight = $('#' + menuName + '-right'),
                menuLeft = $(this).parent().parent(),
                menuRightParent = menuRight.closest('.mega-left');

            if (!window.isMobile()) {
                // clear classes
                menuLeft.find('.active').removeClass('active');
                menuRightParent.removeClass('has-sub-asset');
                menuRightParent.find('.active-list').removeClass('active-list');
                // add classes
                $(this).addClass('active');
                menuRight.parent().addClass('active-list');
                // add a class to the parent Ul if a third level asset is displayed; css-hides the top level asset
                if (menuRight.parent().next().hasClass('sub-asset-1') || menuRight.parent().next().hasClass('sub-asset-2')) {
                    menuRightParent.addClass('has-sub-asset');
                }
            }
        },
        50
    );
};

function navbarBackBtn() {
    $('.navbar-nav').on('click', '.back', event => {
        event.preventDefault();
        clearSelection(event.target);
    });
}

function navbarToggler() {
    $('.navbar-toggler').click(event => {
        event.preventDefault();
        if ($('body').hasClass('mobile-menu-in')) {
            toggleDrawer('close');
            $('.header-nav').addClass('transparent-nav');
        } else {
            toggleDrawer('open');
            $('.header-nav').removeClass('transparent-nav');
        }
    });

    $('html').on('click', '.mobile-menu-in .modal-background', () => toggleDrawer('close'));
}

function mobileSearchFix() {
    // Prevent iOS from scrolling to bad position on search field input focus
    $('.search-mobile .search-field').focus(event => {
        if (window.isMobile()) {
            var currentScrollPosition = $('html').scrollTop();
            setTimeout(() => {
                $('html').scrollTop(currentScrollPosition);
                $('.modal-background').show();
            }, SiteConstants.TransitionSpeed);
        }
    });
}

function containMegaMenu() {
    //keep the megamenus within the container set
    $('.non-full-mega .megamenu > .dropdown-menu').each(function(index) {
        var $left = $(this).parent().position().left;
        $(this).css({
            'max-width': $windowWidth - $left - SiteConstants.Spacer + 'px',
        })
    });
};

function closeButton() {
    $('.close-button').on('click',function(){
        $(this).closest('.tab-pane').removeClass('show active')
    })
}
function displayTabDesktop() {
    $('.nav-link').on('click',function(){
        var tabDesktop = $(this).data('id')
        var selector = '#' + tabDesktop
        $('.promo-tabs').removeClass('show active')
        $(selector).addClass('active')
    })
}
function closeButtonMobile() {
    $('.clp-close-button').on('click', function() {
        $('.slider-container').addClass('hidden-slide')
    })
}
function displaySliderMobile() {
    $('.clp-title').on('click', function() {
        $(this).closest('.slider-container').removeClass('hidden-slide')
    })
}

function dropdownAccessibility() {
    var headerRightSection = $('.header .header-column-3');
    var account = headerRightSection.find('#dropdownAccountSelector');
    var wishlist = headerRightSection.find('#dropdownWishlistSelector');
    var accountMenu = headerRightSection.find('.dropdown-menu[aria-labelledby="dropdownAccountSelector"]');
    var wishlistMenu = headerRightSection.find('.dropdown-menu[aria-labelledby="dropdownWishlistSelector"]');
    var accountItems = accountMenu.find('.dropdown-item a').toArray();
    var wishlistItems = wishlistMenu.find('.dropdown-item a').toArray();

    var index = 0;
    function keydownLogic(selector, selectorMenu, dropdownItems) {
        selector.keydown(function (e) {
            if (selectorMenu.hasClass('show')) {
                if (e.which === 27) {
                    selectorMenu.removeClass('show');
                    selector.attr('aria-expanded', 'false');
                }
                if (e.which === 9 || e.which === 40) {
                    dropdownItems[0].focus();
                    index += 1;
                    e.preventDefault();
                }
            }
            if (!selectorMenu.hasClass('show')) {
                if (e.which === 13) {
                    selectorMenu.addClass('show');
                    selector.attr('aria-expanded', 'true');
                    selectorMenu.find('ul > li > a').first().focus();
                    index = 1;
                }
            }
        });
        var shiftTab = false;
        selectorMenu.find('.dropdown-item a').keydown(function (e) {
            if((e.shiftKey && e.keyCode == 9) || e.keyCode == 38) {
                e.preventDefault();
                for(var a = 0; a < dropdownItems.length; a++) {
                    if(dropdownItems[a].text.trim() == $(this).text().trim()) {
                        index = a;
                    }
                }
                index = (index == 0) ? (dropdownItems.length - 1) : index -= 1;
                dropdownItems[index].focus();
                shiftTab = true;
            } else if (e.which === 9 || e.which === 40) {
                if(shiftTab) {
                    index = (index == dropdownItems.length - 1) ? 0 : index += 1;
                    shiftTab = false;
                }
                dropdownItems[index].focus();
                index = (index += 1) % dropdownItems.length;
                e.preventDefault();
            }
            if (e.which === 27) {
                index = 0;
                shiftTab = false;
            }
        });
        selectorMenu.on('mouseleave', function() {
            index = 0;
            shiftTab = false;
        });
    }
    keydownLogic(account, accountMenu, accountItems);
    keydownLogic(wishlist, wishlistMenu, wishlistItems);

    /*function showDropdownOnFocus(selector) {
        headerRightSection.keydown(function (e) {
            var dropdownMenu = selector.parents('.custom-dropdown').find('.dropdown-menu');
            if(e.which === 9 && !dropdownMenu.hasClass('show')) {
                setTimeout(function () {
                    if(selector.hasClass('focus-visible')) {
                        selector.attr('aria-expanded', 'true');
                        dropdownMenu.addClass('show');
                        dropdownMenu.find('ul > li > a').first().focus();
                        index = 1;
                    }
                }, 10);
            }
        });
    }
    showDropdownOnFocus(account);
    showDropdownOnFocus(wishlist);*/
}
// Make search suggestions navigable via arrow keys
function searchAccessibility() {
    $('.search-field').keydown(function(e) {
        if ($('.suggestions.container').length > 0) {
            if (e.which === 40) {
                e.preventDefault();
                $('.suggestions.container a')[0].focus();
            }
            if (e.which === 38) {
                e.preventDefault();
                $('.suggestions.container a').focus();
            }
            if (e.which === 9) { // Hide suggestions when tabfocused out of search
                $('.suggestions.container').css('display', 'none');
            }
        }
    });
    $('.suggestions-wrapper').keydown(function(e) {
        if (e.which === 39 || e.which === 40) {
            e.preventDefault();
            if ($(e.target).parent().next().length > 0) {
                $(e.target).parent().next().find('a').focus();
            } else {
                var nextItem = $(e.target).parent().parent().nextAll('.suggestions-items')[0];
                if (nextItem) {
                    $(nextItem).find('a')[0].focus();
                }
            }
        }
        if (e.which === 37 || e.which === 38) {
            e.preventDefault();
            if ($(e.target).parent().prev().length > 0) {
                $(e.target).parent().prev().find('a').focus();
            } else {
                var prevItem = $(e.target).parent().parent().prevAll('.suggestions-items')[0];
                if (prevItem) {
                    $(prevItem).find('a').focus();
                }
            }
        }
        if (e.which === 32) { // Trigger clicking of suggestion links with spacebar
            e.preventDefault();
            $(e.target)[0].click();
        }
        if (e.which === 27 || e.which === 9) { // Press tab or escape on suggestions to hide suggestions and focus reset button
            e.preventDefault();
            $('.suggestions.container').css('display', 'none');
            $('.site-search .reset-button').focus();
        }
    });
}
function mainMenuADA() {
    var expandBtn = $('.navbar-nav .nav-item').find('.expand-btn-sronly'),
        dropdownMenu = $('.dropdown-menu'),
        dropdownItem = $('.dropdown-item');
    expandBtn.on('click', function() {
        var subMenu = $(this).next(dropdownMenu);
        var thisItem = $(this).closest(dropdownItem);
        if(subMenu.length) {
            thisItem.addClass('show');
            subMenu.addClass('show').attr('aria-hidden', 'false');
            var subMenuItems = subMenu.find('a');
            subMenuItems[0].focus();
            subMenuItems.keydown(function (e) {
                if (e.which === 9) {
                    e.preventDefault();
                }
                if (e.which === 27) {
                    e.preventDefault();
                    var ths = $(this);
                    var levelTwoItem = ths.closest(dropdownMenu).closest(dropdownItem);
                    ths.closest(dropdownMenu).removeClass('show').attr('aria-hidden', 'true');
                    ths.parents('.nav-item').addClass('show').attr('aria-hidden', 'false');
                    levelTwoItem.closest(dropdownMenu).addClass('show').attr('aria-hidden', 'true');
                    levelTwoItem.removeClass('show');
                    ths.closest(dropdownMenu).removeClass('show');
                    levelTwoItem.find('> a')[0].focus();
                }
            });
        }
    });
}

module.exports = {
    displayTabDesktop: displayTabDesktop,
    displaySliderMobile: displaySliderMobile,
    closeButtonMobile: closeButtonMobile,
    closeButton: closeButton,
    clearSelection: clearSelection,
    toggleDrawer: toggleDrawer,
    headerBanner: headerBanner,
    kbAccessibility: kbAccessibility,
    searchExpand: searchExpand,
    dropdownMenu: dropdownMenu,
    megaLeftHover: megaLeftHover,
    navbarBackBtn: navbarBackBtn,
    navbarToggler: navbarToggler,
    mobileSearchFix: mobileSearchFix,
    containMegaMenu: containMegaMenu,
    dropdownAccessibility: dropdownAccessibility,
    searchAccessibility: searchAccessibility,
    mainMenuADA: mainMenuADA
};

