'use strict';

var core = require('core/components/modal');
var abSlider = require('core/components/slider');

// Override core to reinitialize slider (this fix is from newer AB)
core.onModalLoaded = function () {
    $('body').on('modal:loaded', function(event, modal) {
        var $modal = $(modal);

        // Check for modal-scrollbody helper class and adjust body max-height if found
        if ($modal.find('.modal-scrollbody').length
            || $modal.find('.quick-view-dialog').length
            || $modal.find('.choose-bonus-product-dialog').length) {
            core.setModalBodyMaxHeight($modal);
        }

        // Reinitialize mobile modal sliders to prevent tiny slider error on drag
        if (window.isMobile()) {
            var $sliderContainer = $modal.find('.slider-container');
            if ($sliderContainer.find('.slider').length) {
                var tns = $sliderContainer.data('tns');
                if (tns) {
                    tns.destroy();
                    tns.rebuild();
                    abSlider.initializeSliders($modal);
                }
            }
        }
    });
}

/**
 * replaces the content in the modal window on for the selected product variation.
 * @param {string} selectedValueUrl - url to be used to retrieve a new product model
 */
function fillModalElement(selectedValueUrl, data, $modal) {
    $.ajax({
        url: selectedValueUrl,
        method: 'GET',
        data: data,
        dataType: 'json',
        success: function (data) {
            var parsedHtml = core.parseHtml(data.renderedTemplate);

            $modal.find('.modal-body').empty();
            $modal.find('.modal-body').html(parsedHtml.body);
            $modal.find('.modal-footer').html(parsedHtml.footer);
            $modal.find('.modal-header .close .sr-only').text(data.closeButtonText);
            $modal.find('.enter-message').text(data.enterDialogMessage);
            $modal.modal('show');

            if (data.dialogTitle) {
                $modal.find('.modal-header').prepend('<div class="modal-title">' + data.dialogTitle + '</div>')
            }
        }
    });
}

function initCustomModal() {
    $('body').on('click', '.custom-modal', function (e) {
        e.preventDefault();
        var cid = $(this).attr('data-cid');
        var title = $(this).attr('data-title');
        var dialogClasses = $(this).attr('data-dialog-classes');
        var selectedValueUrl = $(this).attr('href');

        core.getModalHtmlElement('customModal', dialogClasses);
        fillModalElement(selectedValueUrl, {
            cid: cid,
            title: title
        }, $('#customModal'));
    });
}

module.exports = {
    init: function () {
        core.onShownBsModal();
        core.onModalLoaded();
        initCustomModal();
    }
};
