'use strict';
var clientSideValidation = require('core/components/clientSideValidation');

/**
 * Password Messaging Helper Functions
 */
 clientSideValidation.passwordMessaging = function() {
    var $passwordFields = $("input[type='password']");

    if ($passwordFields.length) {
        var $passwordInput = $('.password-validate'),
            passwordLabel = $passwordInput.siblings('label')[0];

        function passwordInputLabel() {
            var $passwordHelper = $(".password-validation-helper li");
            if ($passwordHelper.hasClass("error") || $passwordHelper.hasClass("empty")) {
                $(passwordLabel).addClass('invalid-label');
            } else {
                $(passwordLabel).removeClass('invalid-label');
            }
        };

        $passwordInput.on("keyup", function () {
            if ($(this).hasClass("is-invalid")) {
                passwordInputLabel();
            };

            $(this).on("blur", passwordInputLabel);
        });
    }
};

clientSideValidation.showPassword  = function() {
    $('form:has(input:password)').each(function (i, form) {
        var $form = $(form),
            $showPassword = $form.find("#show-password"),
            $showPasswords = $form.find('.show-password input');

        $showPassword.on('click', event => {
            var $checkbox = $(event.target);

            $showPasswords.each((index, element) => {
                $(element).prop('checked', $checkbox.prop('checked'));
            });
        });
    });
};

clientSideValidation.getOperatingSystem = function () {
    var isApplePaySupport = window.ApplePaySession && window.ApplePaySession.canMakePayments();
    var applepayContainers = $('.applepay-block-container, .nav-item.applepay-tab-wrapper');
    if (isApplePaySupport) {
        applepayContainers.removeClass('d-none');
    } else {
        applepayContainers.addClass('d-none');
    }
}

/**
 * Validates email and password confirmations
 */
function registrationValidation($registrationForm) {
    var $emailInput = $registrationForm.find("input[id='registration-form-email']");
    var $emailConfirmationInput = $registrationForm.find("input[id='registration-form-email-confirm']");
    var $passwordInput = $registrationForm.find("input[id='registration-form-password']");
    var $passwordConfirmInput = $registrationForm.find("input[id='registration-form-password-confirm']");
    var validationMessage;

    if ($emailInput.val().toLowerCase() !== $emailConfirmationInput.val().toLowerCase()) {
        $emailConfirmationInput.addClass('is-invalid');
        validationMessage = $emailConfirmationInput.data('confirm-mismatch');
        $emailConfirmationInput.parents('.form-group').find('.invalid-feedback')
                    .text(validationMessage);
    }

    if ($passwordInput.val() !== $passwordConfirmInput.val()) {
        $passwordConfirmInput.addClass('is-invalid');
        validationMessage = $passwordConfirmInput.data('confirm-mismatch');
        $passwordConfirmInput.parents('.form-group').find('.invalid-feedback')
            .text(validationMessage);
    }
}

clientSideValidation.buttonClick = function () {
    $('form button[type="submit"], form input[type="submit"]').on('click', function () {
        // clear all errors when trying to submit the form
        clientSideValidation.functions.clearForm($(this).parents('form'));

        var $registrationForm = $("form.registration");
        if ($registrationForm.length) {
            registrationValidation($registrationForm);
        }
    });
},

/**
 * add class to select for styling if first option text is empty
 */
clientSideValidation.floatLabelSelect = function() {
    var floatSelect =  $('.has-float-label').find('select');
    floatSelect.each(function() {
        var emptyClass = $(this).find('option:first').text().trim() == '' ? 'has-empty-option' : '';
        $(this).addClass(emptyClass);
    });
}

module.exports = clientSideValidation;
