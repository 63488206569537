'use strict';

var scrollAnimate = require('core/components/scrollAnimate');

/**
 * Display error messages and highlight form fields with errors.
 * @param {string} parentSelector - the form which contains the fields
 * @param {Object} fieldErrors - the fields with errors
 */
function loadFormErrors(parentSelector, fieldErrors) { // eslint-disable-line
    // Display error messages and highlight form fields with errors.
    $.each(fieldErrors, function (attr) {
        $('*[name=' + attr + ']', parentSelector)
            .addClass('is-invalid')
            .siblings('.invalid-feedback')
            .html(fieldErrors[attr]);
    });
    // Animate to top of form that has errors
    scrollAnimate($(parentSelector));
}

/**
 * Clear the form errors.
 * @param {string} parentSelector - the parent form selector.
 */
function clearPreviousErrors(parentSelector) {
    //default clientSideValidation.js will remove .is-invalid only for html5 errors
    //let our manual call here clear all messaging on .invalid-feedback always
    $(parentSelector).find('.form-control.is-invalid').removeClass('is-invalid');
    $(parentSelector).find('.invalid-feedback').html('');
    $('.error-message').hide();
}

function handleErrorHighlight(form, currentStage) {
    form.closest('.checkout-step').removeClass('has-error').find('.error-text').html('');
    var closestStep = form.closest('.checkout-step');
    var invalid;
    if(currentStage == 'payment') {
        invalid = form.find('.tab-pane.active').find('.is-invalid:visible');

        var billingAddress = $('.billingAddressFields');
        var billingAddressField = billingAddress.find('.form-control.is-invalid');
        if(billingAddressField && billingAddressField.length > 0) {
            invalid = $.merge(invalid, billingAddressField );

            var sameAsShipping = $('.js-same-as-shipping');
            if(sameAsShipping.prop('checked')) {
                sameAsShipping.prop('checked', false);
                billingAddress.removeClass('d-none');
            }
        }
    } else {
        invalid = form.find('.is-invalid:visible');
    }
    
    var formError = false;
    if (invalid && invalid.length) {
        formError = true;
        var feedbackText = (invalid.length == 1) 
                                ? invalid.siblings('label').text().trim() + ' '+ $('.data-checkout-stage').data('single-error-text')
                                :  $('.data-checkout-stage').data('multiple-error-text');
        closestStep.addClass('has-error')
            .removeClass('js-success')
            .find('.error-text').html(feedbackText);
        if(currentStage == 'customer') {
            closestStep.find('.customer-summary').removeClass('js-success');
        }
        if(currentStage == 'shipping' || currentStage == 'payment') {
            closestStep.next('.checkout-step')
                .removeClass('js-success')
                .addClass('has-error')
                .find('.error-text').html(feedbackText);
        }
        $('.checkout-save-btn.place-order').addClass('disabled');
        return formError;
    }
}
function checkoutFormValidationOnBtnClick(form, currentStage) {
    form.closest('.checkout-step').removeClass('has-error').find('.error-text').html('');
    
    if(currentStage == 'payment' && form.find('.credit-card-content').hasClass('active')) {
        form.find('.cardNumber').attr('type', 'text').removeAttr('pattern');
    }

    form.get(0).checkValidity();

    if(currentStage == 'payment' && form.find('.credit-card-content').hasClass('active')) {
        setTimeout(function () {
            form.find('.cardNumber').attr('type', 'tel').attr('pattern', '[0-9]*');
        }, 50);
    }

    if(handleErrorHighlight(form, currentStage)) {
        return true;
    }
}

/**
 * Enable Place Order button on checkout
 * @param {string} parentSelector - the parent form selector.
 */
function handlePlaceOrderBtnDisplay() {
    //For enable place order button
    var isCustSubmitted = $('.customer-container').hasClass('js-success'),
        isShippingSubmitted = $('.shipping-summary').hasClass('js-success'),
        isFulfillmentSubmitted = $('.fulfillment-summary').hasClass('js-success'),
        isPaymentSubmitted = $('.payment-summary').hasClass('js-success'),
        hasErrorMsg = $('.alert-danger.error-message').is(':visible');
    var membershipCheckbox = $('.membership-container').find('#membership-checkbox');
    var isMemberShipChecked = true;
    if((membershipCheckbox && membershipCheckbox.length > 0) && !membershipCheckbox.is(':checked')) {
        isMemberShipChecked = false;
    }
    if(isCustSubmitted && isShippingSubmitted && isFulfillmentSubmitted && isPaymentSubmitted && !hasErrorMsg && isMemberShipChecked) {
        $('.checkout-save-btn.place-order').removeClass('disabled').prop('disabled', false);
    } else {
        $('.checkout-save-btn.place-order').addClass('disabled');
    }
}

module.exports = {
    loadFormErrors: loadFormErrors,
    clearPreviousErrors: clearPreviousErrors,
    checkoutFormValidationOnBtnClick: checkoutFormValidationOnBtnClick,
    handleErrorHighlight: handleErrorHighlight,
    handlePlaceOrderBtnDisplay: handlePlaceOrderBtnDisplay
};
